<template>
  <div class="pattern" style="background:#003b5d; z-index:3003">
    <div class="d-flex justify-content-between align-items-center">
      <div>.</div>
        <div class="text-light px-3 title-main d-flex"  >Πλατφόρμα διαχείρισης και οπτικοποίησης δεδομένων του ΠΚΑ
          <div class="px-1 d-flex align-items-center">
          <p class="text-center px-3 rounded-pill bg-danger " style="font-size:13px;margin-bottom: 0rem!important;">Beta</p>
        </div>
        </div>  
        
        
        <b-button  squared class="btn btn-color-sidebar " style="z-index:1001" v-b-toggle.sidebar-1>&#9776;</b-button>
      
        <!--<div class="">
       <b-dropdown class="px-5 profile-btn" variant="light" style="z-index:1001;">
          <template #button-content >
            <b-icon icon="person-circle" aria-hidden="true" ></b-icon>  demo
          </template>
          <b-dropdown-item to="/dashboard/Profile" v-if="false"> Profile</b-dropdown-item>
          <b-dropdown-item v-if="false">Settings</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item-button variant="danger" @click="onLogout">
            <b-icon icon="box-arrow-right" aria-hidden="true" ></b-icon>
            Logout
          </b-dropdown-item-button>
        </b-dropdown>
       </div>-->
    </div>
    <b-sidebar  right  v-model="checkSidebarVisibility" id="sidebar-1" shadow width="250px" text-variant="light">
      <div class="d-flex justify-content-center mt-3 ">
      <!--<b-img class="text-center shadow-logo rounded-circle" src="/img/logo.png"></b-img>-->
      </div>
      <!--<div class="text-center mt-3">
       <b-dropdown variant="light" style="z-index:1001">
          <template #button-content  >
            <b-icon icon="person-circle" aria-hidden="true" ></b-icon>  demo
          </template>
          <b-dropdown-item to="/dashboard/Profile" v-if="false"> Profile</b-dropdown-item>
          <b-dropdown-item v-if="false">Settings</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item-button variant="danger" @click="onLogout">
            <b-icon icon="box-arrow-right" aria-hidden="true" ></b-icon>
            Logout
          </b-dropdown-item-button>
        </b-dropdown>
      </div>-->
       <hr class="text-white">
      <!--<div class="text-center py-1" style="font-size: 20px;">  
        <div class=" rounded-3 py-2 button-bcl " style="padding-right: 0rem !important;" data-toggle="collapse">
          <b-icon icon="layers-half" font-scale="1.5" class="text-white" aria-hidden="true"></b-icon>
          <router-link  class="text-router p-2 text-white" to="/dashboard/demo" >Γενικά</router-link>
        </div>
     
      </div>-->
     <div class="text-center py-1" style="font-size: 20px;">  
        <div class="d-flex py-2 justify-content-center align-items-center" style="padding-right: 0rem !important;" data-toggle="collapse">
         
          <b-icon icon="info-circle-fill" scale="1.5" class="text-white" ></b-icon>
          <div v-b-modal.modal-1 class="text-router p-2 text-white">Γενικά</div>
         
        </div>
     
    </div>
    
    <div class="text-center py-5" style="font-size: 20px;"> 
    <!--<b-button v-b-modal.modal-2 variant="light" style="font-size: 14px;" block>
      
      <b-icon icon="file-earmark-fill" scale="1.5" class="text-dark" ></b-icon>
      Reports</b-button>-->
        
    </div>
    <div class="text-center py-5" style="font-size: 20px;"> 
    <!--<b-button v-b-modal.modal-2 variant="light" style="font-size: 14px;" block>
      
      <b-icon icon="file-earmark-fill" scale="1.5" class="text-dark" ></b-icon>
      Reports</b-button>-->
       
    </div>

      <template v-slot:footer>
        <b-button @click="onLogout" variant="danger" block squared style="padding: 0.7rem!important;">Αποσύνδεση <i class="fa-solid fa-right-from-bracket px-1"></i></b-button>
            

        

      </template>
  
    </b-sidebar>
    <b-modal id="modal-1" title="Γενικά" hide-footer size="lg"> 
        <div class="text-justify">
        Η πλατφόρμα διαχείρισης και οπτικοποίησης δεδομένων του Παρατηρητηρίου Κλιματικής Αλλαγής ενσωματώνει γεωχωρικά δεδομένα που παράχθηκαν στο πλαίσιο του τρίτου υποέργου της Πράξης «Περιφερειακό Σχέδιο Προσαρμογής στην Κλιματική Αλλαγή (ΠεΣΠΚΑ) Αττικής».

        Τα γεωχωρικά δεδομένα που είναι διαθέσιμα στην πλατφόρμα ανήκουν σε 4 βασικές κατηγορίες:
        <ul>

        <li>Γενικά δεδομένα, α) κοινωνικοοικονομικά-πληθυσμιακά και β) χρήσεων-τύπων γης</li>       
        <li>Δεδομένα που σχετίζονται με προβολές στο μέλλον παραμέτρων της κλιματικής αλλαγής. Συγκεκριμένα οι προβολές στηρίζονται στα επιλεγμένα κλιματικά σενάρια RCP4.5 και RCP8.5, που αφορούν την περίοδο 2031-2050, με αντιπροσωπευτικό έτος το 2046 και την περίοδο 2081-2100, με αντιπροσωπευτικό έτος το 2096, και παρατίθενται σε σχέση με μια παρελθούσα περίοδο αναφοράς, το 1981-2000, με αντιπροσωπευτικό έτος το 1995. Στην πρώτη έκδοση της πλατφόρμας έχουν ενσωματωθεί: α) θερμοκρασιακοί δείκτες όπως π.χ.: ο αριθμός θερμών ημερών, ο αριθμός πολύ θερμών ημερών, ο αριθμός τροπικών νυκτών, η ελάχιστη θερμοκρασία χειμώνα, η μέγιστη ετήσια θερμοκρασία και οι μεταβολές της στάθμης της θάλασσας, και β) μελλοντικές μεταβολές (δείκτες) των ατμοσφαιρικών ρύπων διοξείδιο του αζώτου (ΝΟ2), όζον (Ο3) λεπτά σωματίδια (PM2.5) και αιωρούμενων σωματιδίων PM10</li>
        <li>Διασύνδεση των υφιστάμενων δικτύων επίγειων σταθμών μέτρησης των συγκεντρώσεων ατμοσφαιρικών ρύπων (ΕΔΠΑΡ, ΠΑΝΑΚΕΙΑ, ΙΕΠΒΑ), με στόχο την πληρέστερη απεικόνιση της κατάστασης της ποιότητας της ατμόσφαιρας στην Αττική, τόσο για το παρόν με διαρκή ανανέωση δεδομένων, όσο και για τα προηγούμενα έτη με αποδελτίωση ιστορικών δεδομένων.</li>
        <li>Πρόσθετες πληροφορίες, που αφορούν περιοχές Natura, Υγροτόπους και Δήμους της Περιφέρειας Αττικής.</li>
       
     
      </ul>
      Σημειώνεται ότι η τρέχουσα έκδοση της πλατφόρμας αποτελεί πιλοτική εφαρμογή επίδειξης της πρακτικής και των δυνατοτήτων που μπορούν να παρέχονται από το ΠΚΑ για την πρόσβαση και χρήση καταρχάς στα πολυπληθή δεδομένα που παρήχθησαν κατά την υλοποίηση του ΠεΣΠΚΑ Ι για το σύνολο των τομέων ενδιαφέροντος, και τα οποία δύναται να ενσωματωθούν στην υφιστάμενη πλατφόρμα στο σύνολό τους, αλλά και για δεδομένα που θα παραχθούν από νέα έργα και δράσεις.
    </div>
    </b-modal>
    </div>
  
</template>

<script>
import axios from '../axios-auth'
//import lang from "./json/languege.json"
//import reports_names from "./json/reports_names.json"

//import routes from '../router/index.js'
export default {


    data() {
        return {
            //content_all:lang,
            checkSidebarVisibility: false,
           // reports_names:reports_names,
            loader:false,
        }       
       

    },
    methods:{
      onLogout(){
        this.$store.dispatch('logout')
       
      },
      Readpdf(name){
        var path='reports/'+name
        console.log(path)
        this.loader=true
        axios.get('open-file/', { params: {name: path},responseType: 'blob'}).then(response => {
        //Create a Blob from the PDF Stream
        console.log(response)
            const file = new Blob(
              [response.data], 
              {type: 'application/pdf'});
        //Build a URL from the file
        //console.log(file)
            const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
            window.open(fileURL);
            this.loader=false
        })
      }
    },
}
</script>

<style>

.reports:hover {
  color: #003b5d;
}
.sidebar_footer {
    background-color: #5c5c5c;
    margin-right: 0px!important;
    padding:10px;
}
.pattern{
  background-image: url('../../public/img/pattern.png');
  background-repeat: repeat;
  background-size: contain;
}
.b-sidebar {
  background-image: url('../../public/img/pattern.png');
  background-repeat: repeat;
  background-size: cover;
  background-color: #003b5d!important;
}
.router-link-exact-active {
  color: #e9e9e9 !important;
  
}
.text-router{
  text-decoration: none;
}
.shadow-logo {
    box-shadow: 0rem 0rem 1rem rgb(255 255 255 / 18%) !important;
}
/*
.close{
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(255 ,255, 255) !important;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}*/
button.close {
  color: rgb(0, 0, 0) !important;
}

.btn {
    padding: 1rem 1rem!important;
}
.btn-color-sidebar{
  color: #fff;
  background-color: #6c757d!important; 
  border-color: #6c757d!important;
}
.profile-btn>.btn{
  border-radius: 0rem!important;
}
.title-main{
  font-size: 20px;
}
@media screen and (max-width: 730px) {
  .title-main{
  font-size: 15px;
}
  
}


</style>

